import { serializeWork, serializeIntro, serializeColumn, serializeFinal } from './serializer'

const serializeData = (data, serializer) => {
  const rows = data.values
  return rows.map(serializer)
}

const getIntroSections = () => {
  const apiKey = 'AIzaSyBnjcSlOcYGrIamStJI9c7oOgana3-RuIA'
  const spreadsheetId = '1D12pZ7iZXXAX5y3jAYGM2zloDbIg21o9Z8MhGN1imfo'
  const url = 'https://content-sheets.googleapis.com/v4/spreadsheets/' + spreadsheetId + '/values/secciones-intro!A2:H21?access_token=' + apiKey + '&key=' + apiKey

  return fetch(url)
    .then(res => res.json())
    .then((data) => serializeData(data, serializeIntro))
}

const getColumnSections = () => {
  const apiKey = 'AIzaSyBnjcSlOcYGrIamStJI9c7oOgana3-RuIA'
  const spreadsheetId = '1D12pZ7iZXXAX5y3jAYGM2zloDbIg21o9Z8MhGN1imfo'
  const url = 'https://content-sheets.googleapis.com/v4/spreadsheets/' + spreadsheetId + '/values/secciones-columna!A2:N21?access_token=' + apiKey + '&key=' + apiKey

  return fetch(url)
    .then(res => res.json())
    .then((data) => serializeData(data, serializeColumn))
}

const getFinalSections = () => {
  const apiKey = 'AIzaSyBnjcSlOcYGrIamStJI9c7oOgana3-RuIA'
  const spreadsheetId = '1D12pZ7iZXXAX5y3jAYGM2zloDbIg21o9Z8MhGN1imfo'
  const url = 'https://content-sheets.googleapis.com/v4/spreadsheets/' + spreadsheetId + '/values/secciones-final!A2:I21?access_token=' + apiKey + '&key=' + apiKey

  return fetch(url)
    .then(res => res.json())
    .then((data) => serializeData(data, serializeFinal))
}

const getList = () => {
  const apiKey = 'AIzaSyBnjcSlOcYGrIamStJI9c7oOgana3-RuIA'
  const spreadsheetId = '1D12pZ7iZXXAX5y3jAYGM2zloDbIg21o9Z8MhGN1imfo'
  const url = 'https://content-sheets.googleapis.com/v4/spreadsheets/' + spreadsheetId + '/values/lista!A2:J11?access_token=' + apiKey + '&key=' + apiKey

  return fetch(url)
    .then(res => res.json())
    .then((data) => serializeData(data, serializeWork))
}

const getAll = async () => {
  const [intros, columns, finals] = await Promise.all([getIntroSections(), getColumnSections(), getFinalSections()])
  const ids = intros.map((intro) => intro.id)
  const works = ids.map((id) => ({
    id,
    sections: [
      ...intros.filter((intro) => intro.id === id),
      ...columns.filter((intro) => intro.id === id),
      ...finals.filter((intro) => intro.id === id),
    ],
  }))
  return works
}

export const WorkClient = {
  getList,
  getAll,
}