import React from 'react'
import { string, oneOf, oneOfType, node,  arrayOf } from 'prop-types'
import './Text.css'

const Text = ({
  as,
  children,
  className,
  type,
  color,
  ...props
}) => {

  const TagName = props.href ? 'a' : as

  return (
    <TagName
      className={ `ui-text ui-text--${ type } ${ props.href ? 'ui-link' : '' } ${ className ? className : '' }` }
      { ...props }
    >
      { children }
    </TagName>
  )
}

Text.propTypes = {
  as: oneOf(['span', 'p', 'h3']),
  children: oneOfType([string, node, arrayOf(node)]).isRequired,
  className: string,
  type: oneOf(['small', 'regular']),
  color: oneOf(['dark', 'light']),
  href: string,
}

Text.defaultProps = {
  as: 'p',
  type: 'regular',
}

export { Text }
