const serializeWork = (work) => {
  const [id, company, titleEs, titleEn, descriptionEs, descriptionEn, buttonEs, buttonEn, link, image] = work
  return {
    'es': {
      id,
      title: titleEs,
      company,
      summary: {
        description: descriptionEs,
        detail: !link,
        cta: buttonEs,
        link,
        image,
      },
    },
    'en': {
      id,
      title: titleEn,
      company,
      summary: {
        description: descriptionEn,
        detail: !link,
        cta: buttonEn,
        link,
        image,
      },
    },
  }
}

const serializeIntro = (section) => {
  const [id, titleEs, titleEn, image, company, role, year, link] = section
  return {
    id,
    layout: 'intro',
    'es': {
      title: titleEs,
      image,
      company,
      role,
      year,
      link,
    },
    'en': {
      title: titleEn,
      image,
      company,
      role,
      year,
      link,
    },
  }
}

const serializeColumn = (section) => {
  const [id, titleEs, titleEn, descriptionEs, descriptionEn, ...imagesData] = section
  const perChunk = 3
  const images = imagesData.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
  return {
    id,
    layout: 'column',
    'es': {
      title: titleEs,
      description: descriptionEs.split('\n'),
      images: images.map((image) => ({ image: image[0], alt: image[1].split('\n') })),
    },
    'en': {
      title: titleEn,
      description: descriptionEn.split('\n'),
      images: images.map((image) => ({ image: image[0], alt: image[2].split('\n') })),
    },
  }
}

const serializeFinal = (section) => {
  const [id, titleEs, titleEn, descriptionEs, descriptionEn, titleEs2, titleEn2, descriptionEs2, descriptionEn2] = section
  return {
    id,
    layout: 'final',
    'es': {
      sections: [
        {
          title: titleEs,
          description: descriptionEs.split('\n'),
        },
        {
          title: titleEs2,
          description: descriptionEs2.split('\n'),
        },
      ],
    },
    'en': {
      sections: [
        {
          title: titleEn,
          description: descriptionEn.split('\n'),
        },
        {
          title: titleEn2,
          description: descriptionEn2.split('\n'),
        },
      ],
    },
  }
}

export {
  serializeWork,
  serializeIntro,
  serializeColumn,
  serializeFinal,
}