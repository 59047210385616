import React from 'react'
import { NavLink } from 'react-router-dom'

import { Title } from 'components/title'
import { Logo } from 'components/logo'
import { Arrow } from 'components/arrow'
import { useTranslation } from 'components/language-provider'

import './Home.css'
import { RichTranslation } from 'components/rich-translation'
import { PageLayout } from 'components/page-layout'
import { useIsLoadingTranslations } from 'components/language-provider/LanguageProvider'

const Home = () => {
  const { t } = useTranslation()
  const loading = useIsLoadingTranslations()

  if (loading) return null

  return (
    <PageLayout className="home-page">
      <Logo type="light" className="home-logo" width={ 80 }/>
      <Title
        type="hero"
        className="home-content__title"
      >
        <RichTranslation id="home_title"/>
      </Title>
      <Title
        type="hero"
        className="home-content__subtitle"
      >
        <RichTranslation id="home_subtitle" href="/design-system"/>
      </Title>
      <NavLink to="/about" className="ui-button ui-button--fit ui-button--secondary ">
        { t('home_about_button')}
        <Arrow direction="right" width={ 24 }/>
      </NavLink>
    </PageLayout>
  )
}

export { Home }
