import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { Home } from 'pages/home'
import { About } from 'pages/about'
import { Contact } from 'pages/contact'
import { Works } from 'pages/works'
import { Work } from 'pages/work'
import { DesignSystem } from 'pages/design-system'
import { LanguageProvider } from 'components/language-provider'
import { Header } from 'components/header'
import { Cursor } from 'components/cursor'
import { Badge } from 'components/badge'
import { ScrollToTop } from 'components/scroll-to-top'
import { DataProvider } from 'app/work/context'

export const history = createBrowserHistory()

const App = () => {
  return (
    <Router history={ history }>
      <LanguageProvider>
        <DataProvider>
          <ScrollToTop/>
          <Cursor/>
          <Badge/>
          <Header/>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/design-system">
              <DesignSystem />
            </Route>
            <Route path="/work" exact>
              <Works />
            </Route>
            <Route path="/work/:id">
              <Work />
            </Route>
            <Route path="/">
              <Home />
            </Route>
            <Redirect from="*" to="/"/>
          </Switch>
        </DataProvider>
      </LanguageProvider>
    </Router>
  )
}

export default App
