const KEYS = [
  'about_async_title',
  'about_async_subtitle',
]

const serializeAbout = (data) => {
  const rows = data.values
  return rows.reduce((result, row, index) => {
    const key = KEYS[index] || `about_async_description_${ index - 1 }`
    return {
      es: {
        ...result.es,
        [key]: row[0],
      },
      en: {
        ...result.en,
        [key]: row[1],
      },
    }
  }, {})
}

export {
  serializeAbout,
}