import React, { useContext, useEffect, useState } from 'react'
import { node, string, object } from 'prop-types'
import { IntlProvider, useIntl } from 'react-intl'

import { LANGUAGES, locales } from './constants'
import { AboutClient } from 'app/about/client'
import { HomeClient } from 'app/home/client'

const LanguageContext = React.createContext()
const LANGUAGE_KEY = 'language'

const setStoreSelectedLanguage = language => localStorage.setItem(LANGUAGE_KEY, language)

const getStoredLanguage = defaultLanguage => {
  return localStorage.getItem(LANGUAGE_KEY) ?? defaultLanguage
}

const LanguageProvider = ({ children, defaultLanguage = LANGUAGES.EN }) => {
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState(locales)
  const [ language, setLanguage ] = useState(getStoredLanguage(defaultLanguage))
  const setLanguageAsSpanish = () => changeLanguage(LANGUAGES.ES)
  const setLanguageAsEnglish = () => changeLanguage(LANGUAGES.EN)

  useEffect(() => {
    Promise.all([HomeClient.get(), AboutClient.get()]).then(([homeData, aboutData]) => {
      setMessages(currentMessages => ({
        es: {
          ...currentMessages.es,
          ...homeData.es,
          ...aboutData.es,
        },
        en: {
          ...currentMessages.en,
          ...homeData.en,
          ...aboutData.en,
        },
      }))
      setLoading(false)
    })
  }, [])

  const changeLanguage = language => {
    setLanguage(language)
    setStoreSelectedLanguage(language)
  }

  return (
    <LanguageContext.Provider value={ { loading, messages: messages[language], language, setLanguageAsSpanish, setLanguageAsEnglish } }>
      <IntlProvider locale={ language } messages={ messages[language] }>
        { children }
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: node.isRequired,
  defaultLanguage: string,
  messages: object,
}

const useTranslation = () => {
  const { formatMessage } = useIntl()
  return {
    t: key => formatMessage({ id: key }),
  }
}

const useIsLoadingTranslations = () => {
  const { loading } = useContext(LanguageContext)
  return loading
}

const useAboutTranslations = () => {
  const { messages } = useContext(LanguageContext)
  return Object.keys(messages).filter((key) => key.startsWith('about_async_description'))
}

export { LanguageProvider, LanguageContext, useTranslation, useIsLoadingTranslations, useAboutTranslations }
