import React from 'react'
import { oneOf, number } from 'prop-types'

import './Arrow.css'

const Arrow = ({ direction, width, ...props }) => {
  return (
    <svg { ...props } className={ `ui-arrow--${ direction }` } width={ `${ width }px` } height={ `${ width }px` } viewBox="0 0 8 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>arrow</title>
      <g id="Desktop---1440px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="B-01-HOME" transform="translate(-1363.000000, -69.000000)" fill="currentColor" fillRule="nonzero">
          <g id="MENU" transform="translate(982.000000, 61.000000)">
            <path d="M385.03125,23.890625 C385.742188,21.9453125 386.894531,20.4257812 388.488281,19.3320312 L388.488281,19.3320312 L388.488281,18.4882812 C387.363281,18.8632812 386.304688,19.4960938 385.3125,20.3867188 L385.3125,20.3867188 L385.3125,8 L384.164062,8 L384.164062,20.3867188 C383.171875,19.4960938 382.117188,18.8632812 381,18.4882812 L381,18.4882812 L381,19.3320312 C382.59375,20.4257812 383.746094,21.9453125 384.457031,23.890625 L384.457031,23.890625 L385.03125,23.890625 Z" id="↓"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

Arrow.propTypes = {
  direction: oneOf(['left', 'top', 'right', 'bottom']),
  width: number,
}

Arrow.defaultProps = {
  direction: 'bottom',
  width: 16,
}

export { Arrow }
