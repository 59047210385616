import React, { useEffect, useState } from 'react'
import { any, oneOf, string } from 'prop-types'

import { useTranslation } from 'components/language-provider'
import { Text } from 'components/text'

import './PageLayout.css'

const PageLayout = ({ children, className, color }) => {
  const { t } = useTranslation()
  const [ designNameVisible, setDesignNameVisibility ] = useState(false)
  const [ developerNameVisible, setDeveloperNameVisibility ] = useState(false)

  useEffect(() => {
    if (color === 'dark') {
      document.body.style.color = 'var(--secondary-color)'
      document.body.querySelector('#root').style.backgroundColor = 'var(--primary-color)'
    }
    if (color === 'light') {
      document.body.style.color = 'var(--primary-color)'
      document.body.querySelector('#root').style.backgroundColor = 'var(--primary-color)'
    }
  })

  return (
    <main className={ `page-layout page-layout--${ color } ${ className }` }>
      <section className="page-layout__content">
        { children }
      </section>
      <footer className="home-footer">
        <Text color="light" type="small">
          <span>2020:{ t('footer_design') } </span>
          <strong onMouseEnter={ () => setDesignNameVisibility(true) } onMouseLeave={ () => setDesignNameVisibility(false) }>
            { !designNameVisible &&
              <span className="footer_name_abbr">PS</span>
            }
            { designNameVisible &&
              <span className="footer_name_full">Paula Sempere</span>
            }
          </strong>
          <span> - { t('footer_development') } </span>
          <strong onMouseEnter={ () => setDeveloperNameVisibility(true) } onMouseLeave={ () => setDeveloperNameVisibility(false) }>
            { !developerNameVisible &&
              <a href="https://www.linkedin.com/in/juandiego-martin/" target="_blank" rel="noopener noreferrer" className="footer_name_abbr">JD</a>
            }
            { developerNameVisible &&
              <a href="https://www.linkedin.com/in/juandiego-martin/" target="_blank" rel="noopener noreferrer" className="footer_name_full">Juan Diego</a>
            }
          </strong>
        </Text>
      </footer>
    </main>
  )
}

PageLayout.propTypes = {
  children: any,
  className: string,
  color: oneOf(['dark', 'light']),
}

PageLayout.defaultProps = {
  color: 'dark',
  className: '',
}

export { PageLayout }
