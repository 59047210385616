import React from 'react'

import { PageLayout } from 'components/page-layout'
import { RichTranslation } from 'components/rich-translation'
import { Title } from 'components/title'
import { Text } from 'components/text'
import { Link } from 'components/link'
import { useTranslation } from 'components/language-provider'
import { Arrow } from 'components/arrow'
import { useIsLoadingTranslations, useAboutTranslations } from 'components/language-provider/LanguageProvider'

import { ReactComponent as LinkdinIcon } from 'assets/img/Linkedin-1@1,5x.svg'
import { ReactComponent as DribbleIcon } from 'assets/img/Shape@1,5x.svg'
// import { ReactComponent as InstagramIcon } from 'assets/img/Instagram-1@1,5x.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/Twitter-1@1,5x.svg'
// import { ReactComponent as BehanceIcon } from 'assets/img/Behance-1@1,5x.svg'
import { ReactComponent as EmailIcon } from 'assets/img/email.svg'

import './About.css'

const CV_LINK = 'https://drive.google.com/file/d/1zKKr3uNSU9MWxSXVoYRWgLivQbIwBDtA/view'

const About = () => {
  const { t } = useTranslation()
  const loading = useIsLoadingTranslations()
  const aboutTranslations = useAboutTranslations()

  if (loading) return null

  return (
    <PageLayout color="light">
      <div className="description-section">
        <Title type="hero" className="about-content__title">
          { t('about_async_title')}
        </Title>
        <Title type="hero" className="about-content__subtitle">
          { t('about_async_subtitle')}
        </Title>
        {aboutTranslations.map((key) => {
          return (
            <Text key={ key } className="about-content__description">
              <RichTranslation id={ key }/>
            </Text>
          )
        })}
      </div>
      <div className="resume-section">
        <Title as="h2" className="about-content__title-section">
          { t('about_resume')}
        </Title>
        <a
          className="ui-button ui-button--primary ui-button--fit about-content__download-button"
          href={ CV_LINK }
          target="_blank"
          rel="noopener noreferrer"
        >
          { t('about_resume_download')}
          <Arrow direction="right" width={ 24 }/>
        </a>
      </div>
      <div className="contact-section">
        <Title as="h2" className="about-content__title-section">
          { t('contact_title')}
        </Title>
        <div className="contact-content__email-link">
          <EmailIcon/>
          <Link href="mailto:hello@paulasempere.com">hello@paulasempere.com</Link>
        </div>
        <Title as="h2" className="about-content__title-section">
          { t('contact_become_friends')}
        </Title>
        <ul className="social-links__menu">
          <li className="social-links__item">
            <LinkdinIcon/>
            <Link target="_blank" href="https://www.linkedin.com/in/paulasempere/">Linkedin</Link>
          </li>
          <li className="social-links__item">
            <DribbleIcon/>
            <Link target="_blank" href="https://dribbble.com/paulasmpr">Dribbble</Link>
          </li>
          {/* <li className="social-links__item">
            <InstagramIcon/>
            <Link target="_blank" href="https://www.instagram.com/paulasmpr.design/">Instagram</Link>
          </li> */}
          <li className="social-links__item">
            <TwitterIcon/>
            <Link target="_blank" href="https://twitter.com/paulasmpr">Twitter</Link>
          </li>
          {/* <li className="social-links__item">
            <BehanceIcon/>
            <Link target="_blank" href="https://www.behance.net/">Behance</Link>
          </li> */}
        </ul>
      </div>
    </PageLayout>
  )
}

export { About }
