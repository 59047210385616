import React from 'react'
import { useLocation, NavLink } from 'react-router-dom'

import { Logo } from 'components/logo'
import { Menu } from 'components/menu'

import './Header.css'

const Header = () => {
  const location = useLocation()
  const isHome = location.pathname === '/'

  return (
    <header className="header">
      <div className="header-content">
        { !isHome &&
        <NavLink className="header-logo" exact to="/">
          <Logo width={ 64 }/>
        </NavLink>
        }
        <Menu/>
      </div>
    </header>
  )
}

export { Header }
