import esLocales from 'locales/es'
import enLocales from 'locales/en'

const LANGUAGES = {
  EN: 'en',
  ES: 'es',
}
const locales = {
  [LANGUAGES.EN]: enLocales,
  [LANGUAGES.ES]: esLocales,
}

export { LANGUAGES, locales }