import React, { useState, Fragment } from 'react'
import { func, string } from 'prop-types'

import { useTranslation } from 'components/language-provider'
import { Text } from 'components/text'
import { Button } from 'components/button'
import { ReactComponent as CloseIcon } from 'assets/img/close.svg'

import './FontEditor.css'

const FONTS = {
  DEFAULT: {
    primary: 'Graphik',
    secondary: 'Space Mono',
  },
  SECONDARY: {
    primary: 'Bluu Next',
    secondary: 'Crisom Text',
  },
  TERTIARY: {
    primary: 'Salome',
    secondary: 'Oswald',
  },
  QUATERNARY: {
    primary: 'Fira Sans',
    secondary: 'Roboto Slab',
  },
}

const saveFonts = font => {
  const root = document.documentElement
  document.documentElement.style.getPropertyValue('--primary-font')
  root.style.setProperty('--primary-font', FONTS[font].primary)
  root.style.setProperty('--secondary-font', FONTS[font].secondary)
}

// const getSelectedColor = () => {
//   const color = getComputedStyle(document.documentElement)
//     .getPropertyValue('--primary-color')
//     .replace('#', '')
//     .trim()
//   const COLOR_KEYS = Object.keys(COLORS)
//   return COLOR_KEYS.find(key => COLORS[key].primary === color)
// }

const FontEditor = ({ value, close, onChange }) => {
  const [color, setColor] = useState(value)
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="editor-box__overlay"></div>
      <form className="editor-box" onSubmit={ () => saveFonts(color) }>
        <button className="editor-box__close-button" aria-label={ t('design_system_close') } onClick={ close }>
          <CloseIcon/>
        </button>
        <Text>{ t('design_system_edit_font_title')}</Text>
        <div className="ds-font-editor__grid">
          { Object.keys(FONTS).map(key => (
            <label key={ key } className={ `ds-font-editor__item ${ color === key ? 'ds-font-editor__item--selected' : '' }` }>
              <input type="radio" name="font" value={ key } checked={ color === key } onChange={ () => setColor(key) }/>
              <div className="checkbox"></div>
              <div className={ `ds-font-editor__example ds-font-editor__example--${ FONTS[key].primary.toLowerCase().replace(' ', '-') }` }>
                <p className="ds-font-editor__aa">Aa</p>
                <Text type="small">{ FONTS[key].primary }</Text>
              </div>
              <div className={ `ds-font-editor__example ds-font-editor__example--${ FONTS[key].secondary.toLowerCase().replace(' ', '-') }` }>
                <p className="ds-font-editor__aa">Aa</p>
                <Text type="small">{ FONTS[key].secondary }</Text>
              </div>
            </label>
          ))}
        </div>
        <div className="editor__actions">
          <Button onClick={ close } fit>{ t('design_system_cancel') }</Button>
          <Button
            version="tertiary"
            onClick={ () => {
              saveFonts(color)
              onChange(color)
              close()
            } }
            fit
          >
            { t('design_system_save') }
          </Button>
        </div>
      </form>
    </Fragment>
  )
}

FontEditor.propTypes = {
  value: string.isRequired,
  close: func.isRequired,
  onChange: func.isRequired,
}

export { FontEditor }
