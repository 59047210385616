const TINTS = {
  DEFAULT: {
    primary: ['#392B95', '#4033B2', '#433CCF', '#4444EC', '#5662F1', '#687EF5', '#7A97F8'],
    secondary: ['#D4CFC2', '#DFD8CC', '#E9E2D5', '#F4EBDF', '#F7EFE7', '#FBF3EF', '#FDF9F7'],
  },
  RED: {
    primary: ['#51080B', '#7E0812', '#AD071B', '#DE0425', '#F12A2A', '#FF5653', '#FF8E7D'],
    secondary: ['#D4CFC2', '#DFD8CC', '#E9E2D5', '#F4EBDF', '#F7EFE7', '#FBF3EF', '#FDF9F7'],
  },
  VIOLET: {
    primary: ['#30004E', '#440070', '#55008C', '#6402A2', '#8A1CB7', '#AE38C9', '#D054D9'],
    secondary: ['#C79DD9', '#CBA7E2', '#CFB1EB', '#D4BBF3', '#D8C5F6', '#DCCFF8', '#E2DAFA'],
  },
  GREEN: {
    primary: ['#013B00', '#085504', '#146C0C', '#237E18', '#2B932B', '#3FA64B', '#55B86B'],
    secondary: ['#C1DCA0', '#CFE5A9', '#DDEEB4', '#EBF6BE', '#F2F8C8', '#F8FAD1', '#FCFCDC'],
  },
  BLUE: {
    primary: ['#000059', '#000080', '#0000A0', '#0101B9', '#1C27CD', '#384FDE', '#5478EC'],
    secondary: ['#92AFDE', '#9CBEE8', '#A6CEF0', '#B0DDF8', '#BDE7FA', '#CAF0FC', '#D8F7FE'],
  },
  BLACK: {
    primary: ['#131017', '#1D1A23', '#27242E', '#312E39', '#464450', '#5B5B66', '#71727C'],
    secondary: ['#D3D3D3', '#DDDDDD', '#E8E8E8', '#F2F2F2', '#F5F5F5', '#F8F8F8', '#FBFBFB'],
  },
}

const COLORS = {
  DEFAULT: {
    primary: '4444EC',
    secondary: 'F4EBDF',
  },
  RED: {
    primary: 'DE0425',
    secondary: 'F4EBDF',
  },
  VIOLET: {
    primary: '6402A2',
    secondary: 'D4BBF3',
  },
  GREEN: {
    primary: '237E18',
    secondary: 'EBF6BE',
  },
  BLUE: {
    primary: '0101B9',
    secondary: 'B0DEF8',
  },
  BLACK: {
    primary: '312E39',
    secondary: 'F2F2F2',
  },
}

const FONTS = {
  DEFAULT: {
    primary: 'Graphik',
    secondary: 'Space Mono',
  },
  SECONDARY: {
    primary: 'Bluu Next',
    secondary: 'Crisom Text',
  },
  TERTIARY: {
    primary: 'Salome',
    secondary: 'Oswald',
  },
  QUATERNARY: {
    primary: 'Fira Sans',
    secondary: 'Roboto Slab',
  },
}

const getSelectedColor = () => {
  const color = getComputedStyle(document.documentElement)
    .getPropertyValue('--primary-color')
    .replace('#', '')
    .trim()
  const COLOR_KEYS = Object.keys(COLORS)
  return COLOR_KEYS.find(key => COLORS[key].primary === color)
}

const getSelectedFont = () => {
  const font = getComputedStyle(document.documentElement)
    .getPropertyValue('--primary-font')
    .replace(/"/g, '')
    .trim()
  const FONT_KEYS = Object.keys(FONTS)
  return FONT_KEYS.find(key => FONTS[key].primary === font)
}

export {
  TINTS,
  getSelectedColor,
  FONTS,
  getSelectedFont,
}