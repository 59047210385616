import React, { Fragment, useState, useContext, useEffect } from 'react'
import { oneOf, number } from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'

import { Text } from 'components/text'
import { Arrow } from 'components/arrow'
import { useTranslation, LanguageContext } from 'components/language-provider'

import './Menu.css'

const Menu = ({ type, width }) => {
  const [ isLanguageSelectorVisible, setLanguageSelectorVisibility ] = useState(false)
  const [ isMenuVisible, setMenuVisibility ] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const { language, setLanguageAsSpanish, setLanguageAsEnglish } = useContext(LanguageContext)

  useEffect(() => {
    // setMenuVisibility(false)
    setLanguageSelectorVisibility(false)
  }, [language])

  useEffect(() => {
    setMenuVisibility(false)
    setLanguageSelectorVisibility(false)
  }, [location.pathname])

  return (
    <Fragment>
      <nav className="menu">
        <ul>
          <li>
            <NavLink to="/about">
              <Text as="span">{ t('menu_link_about') }</Text>
            </NavLink>
          </li>
          <li>
            <NavLink to="/work">
              <Text as="span">{ t('menu_link_work') }</Text>
            </NavLink>
          </li>
          <li>
            <NavLink to="/design-system">
              <Text as="span">{ t('menu_link_design_system') }</Text>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact">
              <Text as="span">{ t('menu_link_contact') }</Text>
            </NavLink>
          </li>
          <div className="language-selector">
            <button
              className="language-selector__button"
              aria-haspopup="true"
              aria-expanded={ isLanguageSelectorVisible }
              onClick={ () => setLanguageSelectorVisibility(v => !v) }
            >
              <Text>
                <span className="language-selector__title">{ language.toUpperCase() }</span>
                <Arrow aria-hidden="true" direction={ isLanguageSelectorVisible ? 'top' : 'bottom' } width={ 16 }/>
              </Text>
            </button>
            {
              isLanguageSelectorVisible &&
              <div className="language-selector__menu" role="menu">
                <button
                  className={ `language-selector__menu-item ${ language === 'en' ? 'language-selector__menu-item--selected ' : '' }` }
                  onClick={ setLanguageAsEnglish }
                >
                  English
                </button>
                <button
                  className={ `language-selector__menu-item ${ language === 'es' ? 'language-selector__menu-item--selected ' : '' }` }
                  onClick={ setLanguageAsSpanish }
                >
                  Español
                </button>
              </div>
            }
          </div>
        </ul>
      </nav>
      <button className="hamburguer" onClick={ () => setMenuVisibility(v => !v) }>
        <div className="hamburguer__line"></div>
        <div className="hamburguer__line"></div>
        <div className="hamburguer__line"></div>
      </button>
      <nav className={ `mobile-menu ${ isMenuVisible ? 'mobile-menu--visible' : '' }` }>
        <button className="mobile-menu__close-button" aria-label="Cerrar menú" onClick={ () => setMenuVisibility(v => !v) }></button>
        <ul>
          <li>
            <NavLink to="/about">
              <span>{ t('menu_link_about') }</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/work">
              <span>{ t('menu_link_work') }</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact">
              <span>{ t('menu_link_contact') }</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/design-system">
              <span>{ t('menu_link_design_system') }</span>
            </NavLink>
          </li>
        </ul>
        <div className="language-selector__menu" role="menu">
          <button
            className={ `language-selector__menu-item ${ language === 'en' ? 'language-selector__menu-item--selected ' : '' }` }
            onClick={ setLanguageAsEnglish }
          >
            <Text>English</Text>
          </button>
          <button
            className={ `language-selector__menu-item ${ language === 'es' ? 'language-selector__menu-item--selected ' : '' }` }
            onClick={ setLanguageAsSpanish }
          >
            <Text>Español</Text>
          </button>
        </div>
      </nav>
    </Fragment>
  )
}

Menu.propTypes = {
  type: oneOf(['light', 'dark']),
  width: number,
}

Menu.defaultProps = {
  type: 'dark',
  width: 40,
}

export { Menu }
