import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import './Badge.css'

const Badge = () => {
  const location = useLocation()
  const isDesignSystem = location.pathname === '/design-system'

  if (isDesignSystem) return null

  return (
    <div className="badge__wrapper">
      <RouterLink
        to='/design-system'
        className='badge'
      >
        Design System Editor
      </RouterLink>
    </div>
  )
}

export { Badge }
