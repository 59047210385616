import React, { useState } from 'react'
import { string, number } from 'prop-types'

import { useTranslation } from 'components/language-provider'
import { Title } from 'components/title'
import { Text } from 'components/text'
import { Button } from 'components/button'
import { PageLayout } from 'components/page-layout'
import { ColorEditor } from './components/color-editor'
import { FontEditor } from './components/font-editor'

import { ReactComponent as PencilIcon } from 'assets/img/pencil.svg'
import {
  // TINTS,
  FONTS,
  getSelectedColor,
  getSelectedFont,
} from './constants'

import './DesignSystem.css'

const ColorItem = ({ name, value }) => {
  return (
    <div className="ds-color__item">
      <div className={ `ds-color__example ds-color__example--${ value }` }></div>
      <div className="ds-color__info">
        <Text className="ds-color__title"><strong>{ name }</strong></Text>
        <Text>#{ value }</Text>
      </div>
    </div>
  )
}

ColorItem.propTypes = {
  name: string,
  value: string,
}

const SpaceItem = ({ className, name, size }) => {
  const { t } = useTranslation()

  const info = size === 1
    ? '8px'
    : `${ size } ${ t('design_system_spaces_units') } | ${ size * 8 }px`
  return (
    <div className={ `ds-space__item ${ className ? className : '' }` }>
      <div className="ds-space__info">
        <Text className="ds-space__title"><strong>{ name }</strong></Text>
        <Text>{ info }</Text>
      </div>
      <div className={ `ds-space__example ds-space__example--${ size }` }></div>
    </div>
  )
}

SpaceItem.propTypes = {
  className: string,
  name: string,
  size: number,
}

const DesignSystem = () => {
  const [colorSelectorVisible, setColorSelectorVisibility] = useState(false)
  const [selectedColor, setSelectedColor] = useState(getSelectedColor())
  const [fontSelectorVisible, setFontSelectorVisibility] = useState(false)
  const [selectedFont, setSelectedFont] = useState(getSelectedFont())
  const { t } = useTranslation()

  return (
    <PageLayout color="light">
      <Title
        type="hero"
        className="ds-content__title"
      >
        { t('design_system_title') }
      </Title>
      <div className="ds-content__section-list">
        <div className="ds-content__section">
          <Text>{ t('design_system_subtitle') }</Text>
        </div>
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_palette') }</Title>
          <div className="ds-color__group">
            <ColorItem name="Primary A" value="4444EC"/>
            <ColorItem name="Primary B" value="F4EBDF"/>
          </div>
          <Button onClick={ () => setColorSelectorVisibility(v => !v) }>
            <span>{ t('design_system_edit') }</span>
            <PencilIcon/>
          </Button>
          { colorSelectorVisible &&
            <ColorEditor
              value={ selectedColor }
              close={ () => setColorSelectorVisibility(false) }
              onChange={ setSelectedColor }
            />
          }
        </div>
        {/* <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_tints') }</Title>
          { Object.keys(TINTS[selectedColor]).map((TINT, index) => {
            const colors = TINTS[selectedColor][TINT]
            return (
              <div key={ index } className="ds-tints__item">
                <Text as="h3" className="ds-tints__item-title">{ t(`design_system_${ TINT.toLowerCase() }`) }</Text>
                <div className="ds-tints__item-sample">
                  { colors.map(color => (
                    <div key={ color } className="ds-tints__item-color" style={ { backgroundColor: color } }></div>
                  ))}
                </div>
                <Text>100%</Text>
              </div>
            )
          })}
        </div> */}
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_grid') }</Title>
          <SpaceItem className="ds-space__item--grid" name={ `1 ${ t('design_system_spaces_unit') }` } size={ 1 }/>
        </div>
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_spaces') }</Title>
          <SpaceItem name="XSmall" size={ 2 }/>
          <SpaceItem name="Small" size={ 3 }/>
          <SpaceItem name="Medium" size={ 4 }/>
          <SpaceItem name="Large" size={ 8 }/>
          <SpaceItem name="XLarge" size={ 16 }/>
        </div>
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_fonts') }</Title>
          <div className="ds-font__group">
            <div className="ds-font__item">
              <div className="ds-font__example">
                <div className="ds-font__example-primary--bold">Aa</div>
                <div className="ds-font__example-primary--regular">Aa</div>
              </div>
              <div className="ds-font__info">
                <Text><strong>Primary Font</strong></Text>
                <Text>{ FONTS[selectedFont].primary }</Text>
              </div>
            </div>
            <div className="ds-font__item">
              <div className="ds-font__example">
                <div className="ds-font__example-secondary--bold">Aa</div>
                <div className="ds-font__example-secondary--regular">Aa</div>
              </div>
              <div className="ds-font__info">
                <Text><strong>Secondary Font</strong></Text>
                <Text>{ FONTS[selectedFont].secondary }</Text>
              </div>
            </div>
          </div>
          <Button onClick={ () => setFontSelectorVisibility(v => !v) }>
            <span>{ t('design_system_edit') }</span>
            <PencilIcon/>
          </Button>
          { fontSelectorVisible &&
            <FontEditor
              value={ selectedFont }
              close={ () => setFontSelectorVisibility(false) }
              onChange={ setSelectedFont }
            />
          }
        </div>
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_typefaces') }</Title>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Hero Title</Text>
              <Text className="typeface__info-description" type="small">60px | 68px {t('design_system_typefaces_lineheight')}</Text>
            </div>
            <Title className="typeface__example" as="p" type="hero">Hi, I’m Paula Sempere</Title>
          </div>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Title</Text>
              <Text className="typeface__info-description" type="small">28px | 36px {t('design_system_typefaces_lineheight')}</Text>
            </div>
            <Title className="typeface__example" as="p">Hi, I’m Paula Sempere</Title>
          </div>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Text</Text>
              <Text className="typeface__info-description" type="small">20px | 28px {t('design_system_typefaces_lineheight')}</Text>
            </div>
            <Text className="typeface__example">Hi, I’m Paula Sempere</Text>
          </div>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Small Text</Text>
              <Text className="typeface__info-description" type="small">12px | 20px {t('design_system_typefaces_lineheight')}</Text>
            </div>
            <Text className="typeface__example" type="small">Hi, I’m Paula Sempere</Text>
          </div>
        </div>
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_links') }</Title>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Hero Title</Text>
              <Text className="typeface__info-description" type="small">{ t('design_system_links_underline') }</Text>
            </div>
            <Title className="ui-link" type="hero">Link</Title>
          </div>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Title</Text>
              <Text className="typeface__info-description" type="small">{ t('design_system_links_underline') }</Text>
            </div>
            <Title className="ui-link">Link</Title>
          </div>
          <div className="typeface">
            <div className="typeface__info">
              <Text>Text</Text>
              <Text className="typeface__info-description" type="small">{ t('design_system_links_underline') }</Text>
            </div>
            <Text className="ui-link">Link</Text>
          </div>
        </div>
        <div className="ds-content__section">
          <Title as="h2" className="ds-content__section-title">{ t('design_system_buttons') }</Title>
          <div className="ds-button">
            <Text className="ds-button__info">Normal</Text>
            <Button onClick={ () => { return } }>Button</Button>
          </div>
          <div className="ds-button">
            <Text className="ds-button__info">Hover</Text>
            <Button className="ds-button__button--hover" onClick={ () => { return } }>Button</Button>
          </div>
          <div className="ds-button">
            <Text className="ds-button__info">Pressed</Text>
            <Button className="ds-button__button--pressed" onClick={ () => { return } }>Button</Button>
          </div>
          <div className="ds-button">
            <Text className="ds-button__info">Focus</Text>
            <Button className="ds-button__button--focus" onClick={ () => { return } }>Button</Button>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export { DesignSystem }
