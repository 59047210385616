import React from 'react'
import { string, oneOf, oneOfType, node,  arrayOf } from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import './Link.css'

const Link = ({
  children,
  className,
  type,
  color,
  href,
  to,
  ...props
}) => {

  if (href) {
    return (
      <a
        href={ href }
        className={ `ui-link ui-text ui-text--${ color } ui-text--${ type } ${ className }` }
        { ...props }
      >
        { children }
      </a>
    )
  }

  return (
    <RouterLink
      to={ to }
      className={ `ui-link ui-text ui-text--${ color } ui-text--${ type } ${ className }` }
      { ...props }
    >
      { children }
    </RouterLink>
  )
}

Link.propTypes = {
  children: oneOfType([string, node, arrayOf(node)]).isRequired,
  className: string,
  type: oneOf(['small', 'regular']),
  color: oneOf(['dark', 'light']),
  href: string,
  to: string,
}

Link.defaultProps = {
  type: 'regular',
  color: 'dark',
}

export { Link }
