import React from 'react'
import { string, func, bool, oneOf, oneOfType, arrayOf, node } from 'prop-types'
import './Button.css'

const Button = ({ children, className, onClick, version, fit, ...props }) => {
  return (
    <button
      className={ `ui-button ui-button--${ version } ${ fit ? 'ui-button--fit' : '' } ${ className ? className : '' }` }
      onClick={ onClick }
      { ...props }
    >
      { children }
    </button>
  )
}

Button.propTypes = {
  children: oneOfType([string, node, arrayOf(node)]).isRequired,
  className: string,
  onClick: func.isRequired,
  version: oneOf(['primary', 'secondary', 'tertiary']),
  fit: bool,
}

Button.defaultProps = {
  type: 'button',
  version: 'primary',
}

export { Button }
