const KEYS = [
  'home_title',
  'home_subtitle',
]

const serializeHome = (data) => {
  const rows = data.values
  return rows.reduce((result, row, index) => {
    const key = KEYS[index]
    return {
      es: {
        ...result.es,
        [key]: row[0],
      },
      en: {
        ...result.en,
        [key]: row[1],
      },
    }
  }, {})
}

export {
  serializeHome,
}