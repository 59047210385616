import React from 'react'

import { Title } from 'components/title'
import { Link } from 'components/link'
import { useTranslation } from 'components/language-provider'

import { ReactComponent as LinkdinIcon } from 'assets/img/Linkedin-1@1,5x.svg'
import { ReactComponent as DribbleIcon } from 'assets/img/Shape@1,5x.svg'
// import { ReactComponent as InstagramIcon } from 'assets/img/Instagram-1@1,5x.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/Twitter-1@1,5x.svg'
// import { ReactComponent as BehanceIcon } from 'assets/img/Behance-1@1,5x.svg'
import { ReactComponent as EmailIcon } from 'assets/img/email.svg'

import './Contact.css'
import { PageLayout } from 'components/page-layout'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <PageLayout>
      <Title
        type="hero"
        className="contact-content__title"
      >
        { t('contact_title')}
      </Title>
      <div className="contact-content__email-link">
        <EmailIcon/>
        <Link
          href="mailto:hello@paulasempere.com"
          color="light">hello@paulasempere.com</Link>
      </div>
      <Title as="h2" className="contact-content__title-section">
        { t('contact_become_friends')}
      </Title>
      <ul className="social-links__menu">
        <li className="social-links__item">
          <LinkdinIcon/>
          <Link color="light" target="_blank" href="https://www.linkedin.com/in/paulasempere/">Linkedin</Link>
        </li>
        <li className="social-links__item">
          <DribbleIcon/>
          <Link color="light" target="_blank" href="https://dribbble.com/paulasmpr">Dribbble</Link>
        </li>
        {/* <li className="social-links__item">
          <InstagramIcon/>
          <Link color="light" target="_blank" href="https://www.instagram.com/paulasmpr.design/">Instagram</Link>
        </li> */}
        <li className="social-links__item">
          <TwitterIcon/>
          <Link color="light" target="_blank" href="https://twitter.com/paulasmpr">Twitter</Link>
        </li>
        {/* <li className="social-links__item">
          <BehanceIcon/>
          <Link color="light" target="_blank" href="https://www.behance.net/">Behance</Link>
        </li> */}
      </ul>
    </PageLayout>
  )
}

export { Contact }
