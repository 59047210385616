import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { object } from 'prop-types'

import { Title } from 'components/title'
import { Text } from 'components/text'
import { useTranslation, LanguageContext } from 'components/language-provider'
import { WorkLayout } from 'components/work-layout'
import { Link } from 'components/link'
import { useDataWork } from 'app/work/context'

import './Work.css'

const IntroSection = ({ content }) => {
  const { t } = useTranslation()

  return (
    <div className="work-section">
      <img className="work-section__image" src={ content.image } alt="" />
      <div className="work-section__info">
        <Text className="work-section__details"><strong>{ t('work_company') }</strong></Text>
        <Text className="work-section__details">{ content.company }</Text>
        <Text className="work-section__details"><strong>{ t('work_role') }</strong></Text>
        <Text className="work-section__details">{ content.role }</Text>
        <Text className="work-section__details"><strong>{ t('work_year') }</strong></Text>
        <Text className="work-section__details">{ content.year }</Text>
        <Link target="_blank" href={ content.link }><strong>{ t('work_visit') }</strong></Link>
      </div>
    </div>
  )
}

IntroSection.propTypes = {
  content: object,
}

const ColumnSection = ({ content }) => {
  return (
    <div className="work-section work-section--column">
      <div className="work-section__info">
        <Title as="h2" className="work-section__title">{ content.title }</Title>
        <div className="work-section__description-content">
          { content.description.map((descriptionItem, key) => {
            if (!descriptionItem.type) return <Text key={ key } className="work-section__description">{ descriptionItem }</Text>
            if (descriptionItem.type === 'text') return <Text key={ key } className="work-section__description">{ descriptionItem.value }</Text>
            if (descriptionItem.type === 'list') return (
              <ul key={ key } className="work-section__list">
                { descriptionItem.value.map((text, key) => <li key={ key }><Text key={ key } className="work-section__description">{ text }</Text></li>) }
              </ul>
            )
            return null
          }) }
        </div>
      </div>
      { content.images && content.images.map(({ image, alt }, key) => (
        <figure key={ key } className="work-section__image">
          <img src={ image } alt="" />
          <figcaption>
            { alt.map((text, key) => <Text key={ key } type="small">{ text }</Text>)}
          </figcaption>
        </figure>
      ))}
    </div>
  )
}

ColumnSection.propTypes = {
  content: object,
}

const FinalSection = ({ content }) => {
  const { sections } = content
  return (
    <div className="work-section work-section--column">
      <div className="work-section__info">
        <Title className="work-section__title">{ sections[0].title }</Title>
        <div className="work-section__description-content">
          <ul className="work-section__list">
            { sections[0].description.map((text, key) => <li key={ key }><Text className="work-section__description">{ text }</Text></li>)}
          </ul>
        </div>
      </div>
      <div className="work-section__info">
        <Title className="work-section__title">{ sections[1].title }</Title>
        <div className="work-section__description-content">
          { sections[1].description.map((text, key) => <Text key={ key } className="work-section__description">{ text }</Text>)}
        </div>
      </div>
    </div>
  )
}

FinalSection.propTypes = {
  content: object,
}

const SECTIONS_MAP = {
  intro: IntroSection,
  column: ColumnSection,
  final: FinalSection,
}

const Work = () => {
  const { id } = useParams()
  const { language } = useContext(LanguageContext)
  const work = useDataWork(id)

  if (!work) return null

  const { sections } = work
  const { title } = sections[0][language]

  return (
    <WorkLayout color="light">
      <Title type="hero" className="work-title">
        { title }
      </Title>
      {
        sections.map(({ layout, ...content }, index) => {
          const SectionComponent = SECTIONS_MAP[layout]
          if (SectionComponent) return <SectionComponent key={ index } content={ content[language] }/>
          return null
        })
      }
    </WorkLayout>
  )
}

export { Work }
