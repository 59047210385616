import React, { useState, Fragment } from 'react'
import { func, string } from 'prop-types'

import { useTranslation } from 'components/language-provider'
import { Text } from 'components/text'
import { Button } from 'components/button'
import { ReactComponent as CloseIcon } from 'assets/img/close.svg'

import './ColorEditor.css'

const COLORS = {
  DEFAULT: {
    primary: '4444EC',
    secondary: 'F4EBDF',
    light: '7A97F8',
    translucid: 'rgba(122,151,248,0.3)',
  },
  RED: {
    primary: 'DE0425',
    secondary: 'F4EBDF',
    light: 'FF8E7D',
    translucid: 'rgba(255,142,125,0.3)',
  },
  VIOLET: {
    primary: '6402A2',
    secondary: 'D4BBF3',
    light: '7A97F8',
    translucid: 'rgba(208,84,217,0.3)',
  },
  GREEN: {
    primary: '237E18',
    secondary: 'EBF6BE',
    light: '55B86B',
    translucid: 'rgba(85,184,107,0.3)',
  },
  BLUE: {
    primary: '0101B9',
    secondary: 'B0DEF8',
    light: '5478EC',
    translucid: 'rgba(84,120,236,0.3)',
  },
  BLACK: {
    primary: '312E39',
    secondary: 'F2F2F2',
    light: '71727C',
    translucid: 'rgba(113,114,124,0.3)',
  },
}

const saveColors = color => {
  const root = document.documentElement
  document.documentElement.style.getPropertyValue('--primary-color')
  root.style.setProperty('--primary-color', '#' + COLORS[color].primary)
  root.style.setProperty('--primary-color-light', '#' + COLORS[color].light)
  root.style.setProperty('--primary-color-translucid', COLORS[color].translucid)
  root.style.setProperty('--secondary-color', '#' + COLORS[color].secondary)
}

const ColorEditor = ({ value, close, onChange }) => {
  const [color, setColor] = useState(value)
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="editor-box__overlay"></div>
      <form className="editor-box" onSubmit={ () => saveColors(color) }>
        <button className="editor-box__close-button" aria-label={ t('design_system_close') } onClick={ close }>
          <CloseIcon/>
        </button>
        <Text>{ t('design_system_edit_color_title')}</Text>
        <div className="ds-color-editor__grid">
          { Object.keys(COLORS).map(key => (
            <label key={ key } className={ `ds-color-editor__item ${ color === key ? 'ds-color-editor__item--selected' : '' }` }>
              <input type="radio" name="color" value={ key } checked={ color === key } onChange={ () => setColor(key) }/>
              <div className="checkbox"></div>
              <div className={ `ds-color-editor__example ds-color-editor__example--${ COLORS[key].primary }` }/>
              <div className={ `ds-color-editor__example ds-color-editor__example--${ COLORS[key].secondary }` }/>
            </label>
          ))}
        </div>
        <div className="editor__actions">
          <Button onClick={ close } fit>{ t('design_system_cancel') }</Button>
          <Button
            version="tertiary"
            onClick={ () => {
              saveColors(color)
              onChange(color)
              close()
            } }
            fit
          >
            { t('design_system_save') }
          </Button>
        </div>
      </form>
    </Fragment>
  )
}

ColorEditor.propTypes = {
  value: string.isRequired,
  close: func.isRequired,
  onChange: func.isRequired,
}

export { ColorEditor }
