import React, { useState, useEffect } from 'react'

import './Cursor.css'

const isHoveringAnInteractiveElement = target => {
  let result = false
  const anchorsElements = document.querySelectorAll('a')
  anchorsElements.forEach(el => {
    if (el.contains(target)) {
      result = true
    }
  })
  if (result) return result
  const inputElements = document.querySelectorAll('input')
  inputElements.forEach(el => {
    if (el.contains(target)) {
      result = true
    }
  })
  if (result) return result
  const labelElements = document.querySelectorAll('label')
  labelElements.forEach(el => {
    if (el.contains(target)) {
      result = true
    }
  })
  if (result) return result
  const buttonElements = document.querySelectorAll('button')
  buttonElements.forEach(el => {
    if (el.contains(target)) {
      result = true
    }
  })
  return result
}

const Cursor = () => {
  const [position, setPosition] = useState({ pageX: 0, pageY: 0 })
  const [hoverState, setHoverState] = useState(false)

  useEffect(() => {
    const onMouseMove = e => {
      const { pageX, pageY } = e
      setTimeout(() => {
        setPosition({ pageX, pageY })
      }, 100)
    }
    const onMouseOver = e => {
      if (isHoveringAnInteractiveElement(e.target)) {
        setHoverState(true)
        return
      }
      setHoverState(false)
    }
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseover', onMouseOver)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseover', onMouseOver)
    }
  }, [])

  const gap = hoverState ? 0 : 0

  return (
    <div className={ `cursor ${ hoverState?'cursor--hover': '' }` } style={ { transform: `translate3d(${ position.pageX - gap }px, ${ position.pageY - gap }px, 0px) rotate(15deg)` } }></div>
  )
}

export { Cursor }
