import { serializeHome } from './serializer'

const get = () => {
  const apiKey = 'AIzaSyBnjcSlOcYGrIamStJI9c7oOgana3-RuIA'
  const spreadsheetId = '1D12pZ7iZXXAX5y3jAYGM2zloDbIg21o9Z8MhGN1imfo'
  const url = 'https://content-sheets.googleapis.com/v4/spreadsheets/' + spreadsheetId + '/values/home!A2:B3?access_token=' + apiKey + '&key=' + apiKey

  return fetch(url)
    .then(res => res.json())
    .then(serializeHome)
}

export const HomeClient = {
  get,
}