import React from 'react'
import { string, oneOf, any } from 'prop-types'
import './Title.css'

const Title = ({
  as: TagName,
  children,
  className,
  type,
  color,
  ...props
}) => {

  return (
    <TagName
      className={ `ui-title ui-title--${ color } ui-title--${ type } ${ className ? className : '' }` }
      { ...props }
    >
      { children }
    </TagName>
  )
}

Title.propTypes = {
  as: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  children: any,
  className: string,
  type: oneOf(['hero', 'regular']),
  color: oneOf(['dark', 'light']),
}

Title.defaultProps = {
  as: 'h1',
  type: 'regular',
  color: 'dark',
}

export { Title }
