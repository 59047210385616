import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { Title } from 'components/title'
import { Text } from 'components/text'
import { Arrow } from 'components/arrow'
import { useTranslation, LanguageContext } from 'components/language-provider'
import { PageLayout } from 'components/page-layout'
import { useDataList } from 'app/work/context'

import dribbble_1 from 'assets/img/works/dribbble/1-shot-dribbble.jpg'
import dribbble_2 from 'assets/img/works/dribbble/2-shot-dribbble.jpg'
import dribbble_3 from 'assets/img/works/dribbble/3-shot-dribbble.jpg'
import dribbble_4 from 'assets/img/works/dribbble/4-shot-dribbble.jpg'
import dribbble_5 from 'assets/img/works/dribbble/5-shot-dribbble.jpg'
import dribbble_6 from 'assets/img/works/dribbble/6-shot-dribbble.jpg'
import dribbble_7 from 'assets/img/works/dribbble/7-shot-dribbble.jpg'
import dribbble_8 from 'assets/img/works/dribbble/8-shot-dribbble.jpg'
import './Works.css'

const DRIBBBLE_WORKS = [
  { image: dribbble_1, url: 'https://dribbble.com/shots/12582793-Idea-to-a-recipes-app' },
  { image: dribbble_2, url: 'https://dribbble.com/shots/11798971-Personal-Portfolio-Design-System' },
  { image: dribbble_3, url: 'https://dribbble.com/shots/11798876-Personal-Brand' },
  { image: dribbble_4, url: 'https://dribbble.com/shots/4498855-Testimonials' },
  { image: dribbble_5, url: 'https://dribbble.com/shots/4426992-Error-404-Muchosol' },
  { image: dribbble_6, url: 'https://dribbble.com/shots/4401927-Widgets-2' },
  { image: dribbble_7, url: 'https://dribbble.com/shots/4397470-Favorites-animation' },
  { image: dribbble_8, url: 'https://dribbble.com/shots/4397407-500-Error-Eclipse-Illustration' },
]

const Works = () => {
  const works = useDataList()
  const { t } = useTranslation()
  const { language } = useContext(LanguageContext)

  return (
    <PageLayout color="light">
      <Title type="hero" className="works-title">{ t('works_title')}</Title>
      {
        works.map((work) => {
          const { id, title, company, summary } = work[language]
          return (
            <div key={ id } className="works-section">
              <div className="works-section__info">
                <Text className="works-section__company"><strong>{ company.toUpperCase() }</strong></Text>
                <Title className="works-section__title">{ title }</Title>
                <Text className="works-section__description">{ summary.description }</Text>
                { summary.detail &&
                  <NavLink to={ '/work/' + id } className="ui-button ui-button--fit ui-button--primary ">
                    { summary.cta }
                    <Arrow direction="right" width={ 24 }/>
                  </NavLink>
                }
                {
                  !summary.detail &&
                  <a href={ summary.link } target="_blank" rel="noopener noreferrer" className="work-section__external-link">
                    <Text as="span"><strong>{ summary.cta }</strong></Text>
                    <Arrow direction="right" width={ 24 }/>
                  </a>
                }
              </div>
              <img className="works-section__image" src={ summary.image } alt="" />
            </div>
          )
        })
      }
      <div className="dribbble-section">
        <Title type="hero">Dribbble</Title>
        <div className="dribbble-gallery">
          { DRIBBBLE_WORKS.map(({ image, url }, index) => (
            <a key={ index } href={ url } target="_blank" rel="noopener noreferrer" className="dribble-work__link">
              <img src={ image } alt=""/>
            </a>
          ))}
        </div>
        <div>
          <a href="https://dribbble.com/paulasmpr" target="_blank" rel="noopener noreferrer" className="work-section__external-link">
            <Text as="span"><strong>{ t('work_see_more') }</strong></Text>
            <Arrow direction="right" width={ 24 }/>
          </a>
        </div>
      </div>
    </PageLayout>
  )
}

export { Works }
