import React, { Fragment } from 'react'
import { string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

// import designThinkingImage from 'assets/img/design_thinking.jpg'
// import dogsImage from 'assets/img/paula_dogs.jpg'

import './RichTranslation.css'

// const IMAGES = {
//   design_thinking: designThinkingImage,
//   dogs: dogsImage,
// }
const RichTranslation = ({ id, href }) => {
  const formatBold = msg => {
    return <strong>{msg}</strong>
  }
  const formatLink = rawsg => {
    const [href, text] = rawsg[0].split('#href')
    return <Link className="ui-link" to={ href }>{text}</Link>
  }
  const formatImg = rawsg => {
    const [fileName, text] = rawsg[0].split('#id')
    const url = `https://paulasempere.com/static/media/${ fileName }`
    return (
      <Fragment>
        <span className="ui-link hover-image">
          {text}
          <img alt="" className="hover-image__image" src={ url }/>
        </span>
      </Fragment>
    )
  }

  return (
    <FormattedMessage
      id={ id }
      values={ {
        bold: formatBold,
        link: formatLink,
        img: formatImg,
      } }
    />
  )
}

RichTranslation.propTypes = {
  id: string.isRequired,
  href: string,
}

export { RichTranslation }
