import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { WorkClient } from './client'

const DataContext = React.createContext(null)

export function DataProvider({ children }) {
  const [list, setList] = useState([])
  const [works, setWorks] = useState(null)

  useEffect(() => {
    WorkClient.getList().then(setList)
    WorkClient.getAll().then(setWorks)
  }, [])

  return (
    <DataContext.Provider value={ { list, works } }>
      {children}
    </DataContext.Provider>
  )
}

export function useDataList() {
  const { list } = useContext(DataContext)
  return list
}

export function useDataWork(id) {
  const { works } = useContext(DataContext)
  if (!works) return
  return works.find((work) => work.id === id)
}

DataProvider.propTypes = {
  children: PropTypes.node,
}